import React from 'react'
import Slide from './Slide'
import useDarkMode from '../hook/useDarkMode.js'
import {twitter1, twitter2, twitter3, twitter4, twitter5, twitter6, twitter7} from '../Images/imports'


const TwitterProject = () => {
    useDarkMode()
    window.scrollTo(0, 0)
    return (
        <div className='flex flex-col bg-white dark:bg-black'>
            <Slide imageLink={twitter1}/>
            <Slide imageLink={twitter2}/>
            <Slide imageLink={twitter3}/>
            <Slide imageLink={twitter4}/>
            <Slide imageLink={twitter5}/>
            <Slide imageLink={twitter6}/>
            <Slide imageLink={twitter7}/>
        </div>
    )
}

export default TwitterProject
