import React from 'react'
import VideoGame from './VideoGame'
import useDarkMode from '../hook/useDarkMode.js'

const Cargon = () => {
    useDarkMode()
    return (
        <div className='bg-white dark:bg-black'>
            <VideoGame link='https://i.simmer.io/@cargon21/cargon'/>
        </div>
    )
}

export default Cargon
