import AsciiProject from "./components/AsciiProject";
import Cargon from "./components/Cargon";
import DgsProject from "./components/DgsProject";
import Photography from "./components/Photography";
import Home from "./components/Home";
import MyProjects from "./components/MyProjects";
import NetLogoProject from "./components/NetLogoProject";
import Reshape from "./components/Reshape";
import TwitterProject from "./components/TwitterProject";

import "./CSS/scroller.css"
import { Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path= "/" element={<Home/>}/>
        <Route exact path="/photography" element={<Photography/>} />
        <Route exact path="/projects" element={<MyProjects/>} />
        <Route exact path="/projects/reshape" element={<Reshape/>} />
        <Route exact path="/projects/dgs-project" element={<DgsProject/>} />
        <Route exact path="/projects/cargon" element={<Cargon/>} />
        <Route exact path="/projects/ascii-project" element={<AsciiProject/>} />
        <Route exact path="/projects/twitter-project" element={<TwitterProject/>} />
        <Route exact path="/projects/netlogo-project" element={<NetLogoProject/>} />
      </Routes>
    </div>    
  );
}

export default App;
