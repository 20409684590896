import React from "react";
import { Link } from "react-router-dom";
import Intro from "../components/Intro";
import LanguageCard from "../components/LanguageCard";
import "../CSS/scroller.css";
import {
  bitmojiDark,
  bitmojiLight,
  photographySection,
  githubIcon,
  instagramIcon,
  linkedInIcon,
  biking,
  burgers,
  camping,
  cities,
  climbing,
  boxing,
  music,
  nature,
  photography,
  pizza,
  running,
  strawberries,
  ultimate,
  dog,
} from "../Images/imports";

const Home = () => {
  return (
    //bg-slate-200 dark:bg-slate-900
    <div className="home bg-gradient-to-b from-slate-300 to-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-black">
      <Intro />
      <section className="bg-top bg-cover text-black dark:text-white text-7xl text-center">
        <div id="AboutMe">
          <img
            src={bitmojiLight}
            className="w-2/5 lg:w-1/5 h-auto pt-8 mx-auto block dark:hidden"
            alt="bitmoji of carson"
          />
          <img
            src={bitmojiDark}
            className="w-2/5 lg:w-1/5 h-auto pt-8 mx-auto hidden dark:block"
            alt="bitmoji of carson"
          />
        </div>
        <h2 className="text-3xl md:text-4xl pt-2 font-PangramBold text-sky-700 dark:text-emerald-800">
          About Me
        </h2>

        <p className="lg:hidden text-base md:text-lg w-10/12 mx-auto text-left font-PangramMedium pt-2">
          Hey there! I'm a Software Engineer currently living in Washington DC.
          I like building websites, video games, and whatever else I think of!
        </p>
        <p className="lg:hidden text-base md:text-lg w-10/12 mx-auto text-left font-PangramMedium pt-2">
          I enjoy photography, sports, and much more. You can always catch me
          learning or trying something new!
        </p>

        <p className="hidden lg:block text-base w-8/12 mx-auto text-left font-PangramMedium pt-2">
          Hey there! I'm a Software Engineer currently living in Washington DC.
          I like building websites, video games, and whatever ideas I can come
          up with. Programming has been a passion of mine ever since I took a
          Python class at a local community college during my senior year of
          high school.
        </p>
        <p className="hidden lg:block text-base w-8/12 mx-auto text-left font-PangramMedium pt-2">
          I really enjoy photography, sports, and much much more. You can always
          catch me learning or trying something new!
        </p>

        <div className="lg:w-9/12 lg:mx-auto lg:bg-slate-300 lg:dark:bg-slate-800 lg:rounded-lg mt-8 md:mt-12 lg:py-2">
          <h2 className="text-2xl md:text-3xl pb-4 md:pb-8 text-sky-700 dark:text-emerald-800 font-PangramBold">
            My Skills
          </h2>
          <div className="relative overflow-hidden h-24">
            <div className="absolute right-0 scroller flex justify-around items-center logo text-base whitespace-nowrap ">
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
                  alt="python icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
                  alt="typescript icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg"
                  alt="csharp icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
                  alt="sass icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg"
                  alt="redux icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"
                  alt="mysql icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg"
                  alt="angular icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                  alt="react icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg"
                  alt="flask icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
                  alt="html5 icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg"
                  alt="tailwindcss icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rxjs/rxjs-original.svg"
                  alt="rxjs icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/unity/unity-original.svg"
                  alt="unity icon"
                />
              </div>
            </div>

            <div className="absolute right-0 scroller2 flex justify-around items-center logo text-base whitespace-nowrap ">
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
                  alt="python icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
                  alt="typescript icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg"
                  alt="csharp icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
                  alt="sass icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg"
                  alt="redux icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"
                  alt="mysql icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg"
                  alt="angular icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                  alt="react icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg"
                  alt="flask icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
                  alt="html5 icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg"
                  alt="tailwindcss icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rxjs/rxjs-original.svg"
                  alt="rxjs icon"
                />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/unity/unity-original.svg"
                  alt="unity icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-9/12 lg:mx-auto lg:bg-slate-300 lg:dark:bg-slate-800 lg:rounded-lg md:mt-12 lg:py-2">
          <h2 className="text-2xl md:text-3xl pb-4 md:pb-8 text-sky-700 dark:text-emerald-800 font-PangramBold">
            Things I like
          </h2>
          <div className="relative overflow-hidden h-24">
            <div className="scroller3 absolute left-0 flex justify-around items-center logo text-base whitespace-nowrap">
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={running} alt="running emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={camping} alt="camping emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={burgers} alt="burgers emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={biking} alt="biking emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={cities} alt="cities emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={climbing} alt="climbing emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={boxing} alt="boxing emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={music} alt="music emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={dog} alt="dog emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={nature} alt="nature emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={photography} alt="photography emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={pizza} alt="pizza emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={ultimate} alt="ultimate emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={strawberries} alt="strawberries emoji" />
              </div>
            </div>

            <div className="scroller4 absolute left-0 flex justify-around items-center logo text-base whitespace-nowrap">
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={running} alt="running emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={camping} alt="camping emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={burgers} alt="burgers emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={biking} alt="biking emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={cities} alt="cities emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={climbing} alt="climbing emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={boxing} alt="boxing emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={music} alt="music emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={dog} alt="dog emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={nature} alt="nature emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={photography} alt="photography emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={pizza} alt="pizza emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={ultimate} alt="ultimate emoji" />
              </div>
              <div className="w-16 h-16 md:w-20 md:h-20 mx-3">
                <img src={strawberries} alt="strawberries emoji" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-evenly mt-4 md:mt-16 pb-24 lg:w-10/12 lg:mx-auto">
          <a
            href="https://www.instagram.com/cargon21/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramIcon}
              className="w-16 h-16 md:w-20 md:h-20 \
                        transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              alt="instagram icon"
            />
          </a>

          <a
            href="https://www.linkedin.com/in/carson-sytner"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={linkedInIcon}
              className="w-16 h-16 md:w-20 md:h-20 \
                        transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              alt="linkedin icon"
            />
          </a>

          <a
            href="https://github.com/cargon21"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={githubIcon}
              className="w-16 h-16 md:w-20 md:h-20 \
                    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              alt="github iconn"
            />
          </a>
        </div>
      </section>

      <section className="bg-top bg-cover text-black dark:text-white text-2xl text-center font-PangramMedium">
        <h2
          className="text-3xl md:text-4xl text-sky-700 dark:text-emerald-800 font-PangramBold"
          id="Projects"
        >
          Project Highlights
        </h2>

        <div className="w-10/12 lg:w-9/12 bg-slate-300 dark:bg-slate-800 mx-auto mt-8 py-4 md:py-10 rounded-lg text-base">
          <h2 className="text-2xl md:text-3xl font-PangramBold">
            DGS Game Searcher
          </h2>
          <p className="lg:hidden text-base md:text-lg w-10/12 mx-auto text-left py-1">
            A full stack application that shows the cheapest prices for PC games
            from across the web.
          </p>
          <p className="hidden lg:block text-base md:text-lg w-10/12 mx-auto text-left py-1">
            A full stack application that shows the cheapest prices for PC games
            from across the web. Gamers buy discount PC games from a variety of
            online sellers and often check multiple sites before making a
            purchase. DGS removes this hassle by showing gamers which websites
            are selling their desired game for the cheapest price.
          </p>

          <div className="text-sm md:text-base flex flex-wrap justify-evenly w-10/12 lg:w-1/2 mx-auto md:pt-1">
            <LanguageCard language="Python" />
            <LanguageCard language="Flask" />
            <LanguageCard language="MySQL" />
            <LanguageCard language="Tailwind CSS" />
            <LanguageCard language="HTML" />
          </div>
        </div>

        <div className="w-10/12 lg:w-9/12 bg-slate-300 dark:bg-slate-800 mx-auto mt-8 py-4 md:py-10 rounded-lg text-base">
          <h2 className="text-2xl md:text-3xl font-PangramBold">
            The Tale of Two Tweeters
          </h2>
          <p className="lg:hidden text-base md:text-lg w-10/12 mx-auto text-left py-1">
            A data science project where I apply Tweet statistics to machine
            learning models.
          </p>
          <p className="hidden lg:block text-base md:text-lg w-10/12 mx-auto text-left py-1">
            In this data science project, I take Tweets from students and
            entrepreneurs in the Boston area and calculate various statistics
            about each Tweet. I then apply this data to various machine learning
            models to predict who wrote a given Tweet (student or entrepreneur)
            and when (before the COVID-19 pandemic, during the first few weeks
            of lockdown, or anytime after).
          </p>

          <div className="text-sm md:text-base flex flex-wrap justify-evenly w-10/12 lg:w-1/2 mx-auto md:pt-1">
            <LanguageCard language="Python" />
            <LanguageCard language="Pandas" />
            <LanguageCard language="scikit-learn" />
          </div>
        </div>

        <div className="w-10/12 lg:w-9/12 bg-slate-300 dark:bg-slate-800 mx-auto mt-8 py-4 md:py-10 rounded-lg text-base">
          <h2 className="text-2xl md:text-3xl font-PangramBold">
            Reshape Video Game
          </h2>
          <p className="lg:hidden text-base md:text-lg w-10/12 mx-auto text-left py-1">
            A hyper-casual game where the player changes shape to advance
            further in the level.
          </p>
          <p className="hidden lg:block text-base md:text-lg w-10/12 mx-auto text-left py-1">
            A hyper-casual game where the cube player must change shape and
            avoid falling off platforms to advance further in the level. The
            player can change the cube into a wide rectangle to travel across
            bridges or into a short rectangle to fit underneath bridges. A super
            fun and addicting game!
          </p>
          <div className="text-sm md:text-base flex flex-wrap justify-evenly w-10/12 lg:w-1/4 mx-auto md:pt-1">
            <LanguageCard language="Unity" />
            <LanguageCard language="C#" />
          </div>
        </div>

        <h2 className="text-xl md:text-2xl pt-12 font-PangramBold">
          Want to learn more?
        </h2>
        <Link to="/projects">
          <div
            className="text-base md:text-xl px-4 md:px-6 md:my-3 py-3 md:pt-3 lg:pb-4 rounded-lg inline-block \
                    bg-sky-700 hover:bg-sky-500 dark:bg-emerald-800 lg:dark:hover:bg-emerald-600"
          >
            Explore Projects
          </div>
        </Link>
      </section>

      <section className="bg-top bg-cover text-black dark:text-white text-7xl text-center py-24">
        <h2
          className="text-3xl md:text-4xl md:pb-2 text-sky-700 dark:text-emerald-800 font-PangramBold lg:py-8"
          id="Photography"
        >
          Photography
        </h2>
        <div className="lg:flex lg:justify-center lg:w-10/12 lg:mx-auto">
          <img
            src={photographySection}
            className="w-full md:w-10/12 lg:w-7/12 md:mx-auto h-auto pt-4 lg:pt-0"
            alt="photography"
          />
        </div>

        <Link to="/photography">
          <div
            className="text-base md:text-xl px-4 md:px-6 md:my-3 pb-3 pt-2 md:pt-3 lg:pb-4 rounded-lg inline-block \
                        bg-sky-700 hover:bg-sky-500 dark:bg-emerald-800 lg:dark:hover:bg-emerald-600"
          >
            Explore the Gallery
          </div>
        </Link>
      </section>
    </div>
  );
};

export default Home;
