import React from 'react'

const Slide = ({ imageLink }) => {
    return (
        <div>
            <img className = 'my-12 lg:w-1/2 mx-auto' src={ imageLink } alt='slide'></img>
        </div>
    )
}

export default Slide
