import React from 'react'

const LanguageCard = ({ language }) => {
    return (
        <div className='px-4 my-1 py-2 lg:pt-1 lg:pb-2 bg-sky-700 dark:bg-emerald-800 rounded-lg'>
            { language }
        </div>
    )
}

export default LanguageCard
