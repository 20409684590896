import React from "react";
import { Link } from "react-router-dom";
import Picture from "./Picture.js";
import useDarkMode from "../hook/useDarkMode.js";
import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
} from "../Images/imports.js";

const Photography = () => {
  useDarkMode();
  return (
    <div>
      <div
        className="bg-white dark:bg-black bg-top bg-cover h-screen text-sky-700 dark:text-emerald-800 font-PangramBold text-7xl text-center \
				flex justify-center items-center"
        style={{ transition: "height 999999s" }}
      >
        <Link to={"/"}>
          <div className="absolute top-4 left-4 text-xs md:text-sm text-sky-700 dark:text-emerald-800 font-PangramBold">
            Back to Home
          </div>
        </Link>
        <div className="">
          <h1 className="lg:text-8xl xl:text-9xl 2xl:text-11xl px-4">
            PHOTO GALLERY
          </h1>
          <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl pt-1">
            Less of the mood. More of the feeling
          </h2>
        </div>
      </div>

      <section className="bg-white dark:bg-black grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-1 md:gap-y-0 overflow-hidden">
        <Picture title={img16} alt="photography of landscape in Kyrgyzstan" />
        <Picture title={img2} alt="photography of New York City Skyline" />
        <Picture title={img1} alt="photography of landscape in Kyrgyzstan" />
        <Picture title={img3} alt="photography of foggy bottom tree" />

        <Picture title={img5} alt="photography of landscape in Kyrgyzstan" />
        <Picture title={img7} alt="photography of landscape in Kyrgyzstan" />
        <Picture title={img6} alt="photography of basketball player" />
        <Picture title={img8} alt="photography of building art in New York City" />

        <Picture title={img11} alt="photography of washington monument" />
        <Picture title={img17} alt="photography of Naryn" />
        <Picture title={img10} alt="photography of magic gardens" />      
        <Picture title={img12} alt="photography of pier" />

        <Picture title={img13} alt="photography of yurt in Kyrgyzstan" />
        <Picture title={img9} alt="photography of building in Philadelphia" />
        <Picture title={img14} alt="photography of yurts in Kyrgyzstan" />
        <Picture title={img4} alt="photography of New York City tunnel" />

        <Picture title={img21} alt="photography of palm trees and house" />
        <Picture title={img18} alt="photography of Naryn" />
        <Picture title={img19} alt="photography of dolomites" />
        <Picture title={img20} alt="photography of dolomites landscape" />

        <Picture title={img23} alt="photography of dupont circle" />
        <Picture title={img15} alt="photography of guggenheim museum" />
        <Picture title={img22} alt="photography of dolomites landscape" />
        <Picture title={img24} alt="photography of Philadelphia skyline" />

      </section>
    </div>
  );
};

export default Photography
