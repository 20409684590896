import React from 'react'
import Slide from './Slide'
import {ascii1, ascii2, ascii3, ascii4} from '../Images/imports'
import useDarkMode from '../hook/useDarkMode.js'

const AsciiProject = () => {
    useDarkMode()
    window.scrollTo(0, 0)
    return (
        <div className='flex flex-col bg-white dark:bg-black'>
            <Slide imageLink={ascii1}/>
            <Slide imageLink={ascii2}/>
            <Slide imageLink={ascii3}/>
            <Slide imageLink={ascii4}/>
        </div>
    )
}

export default AsciiProject
