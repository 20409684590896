import React from 'react'

const VideoGame = ({ link }) => {
    return (
        <div className = 'h-screen w-screen flex justify-center items-center'>
            <iframe src= {link} className = 'w-GameWidth h-GameHeight border-8 border-black dark:border-none'></iframe>
        </div>
    )
}

export default VideoGame
