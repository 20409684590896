import React from 'react'
import Nav from './Nav';

const Intro = () => {
    function smoothScroll(section) {
        document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div className = 'bg-top dark:bg-bottom h-screen w-auto text-black dark:text-white text-4xl md:text-5xl lg:text-4xl text-center font-PangramBold \
            bg-lightBackgroundSmall dark:bg-darkBackgroundSmall md:bg-lightBackgroundLarge md:dark:bg-darkBackgroundLarge lg:bg-fixed no-repeat bg-cover' style={{transition: 'height 999999s'}}> 
            <div>
                <div className = 'flex justify-evenly text-base md:text-xl lg:text-base text-sky-700 dark:text-emerald-800 pt-4'>
                    <button onClick={() => smoothScroll('#AboutMe')}>About Me</button>
                    <button onClick={() => smoothScroll('#Projects')}>Projects</button>
                    <button onClick={() =>smoothScroll('#Photography')}>Photography</button>
                    <a href='mailto: carson.sytner@gmail.com'>Contact</a>
                </div>
                <div className = 'w-11/12 h-px md:h-1 lg:h-1 bg-black dark:bg-white mx-auto mt-2'></div>

                <Nav />

                <h1 className = 'pt-20 lg:pt-8'>What's Up,</h1>
                <h1 className = ''>I'm Carson</h1>
                <h1 className = 'pt-24'>Engineer</h1>
                <h1 className = 'pt-2'>Photographer</h1>
                <h1 className = 'pt-2'>Go-Getter</h1>
            </div>
      </div> 
    )
}

export default Intro
