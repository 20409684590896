import React from 'react'

const Picture = (props) => {
    return (
        <div>
            <img src={props.title} className='border-white dark:border-black border-2 md:border-none w-full h-auto' alt={props.alt} />
        </div>
    )
}

export default Picture
