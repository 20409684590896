import React from 'react'
import useDarkMode from '../hook/useDarkMode.js'
import VideoGame from './VideoGame'

const Reshape = () => {
    useDarkMode()
    return (
        <div className='bg-white dark:bg-black'>
            <VideoGame link='https://i.simmer.io/@cargon21/reshape'/>
        </div>
    )
}

export default Reshape
