import React from 'react'
import Slide from './Slide'
import useDarkMode from '../hook/useDarkMode.js'
import {netlogo1, netlogo2} from '../Images/imports'

const NetLogoProject = () => {
    useDarkMode()
    window.scrollTo(0, 0)
    return (
        <div className='flex flex-col bg-white dark:bg-black'>
            <Slide imageLink={netlogo1}/>
            <Slide imageLink={netlogo2}/>
        </div>
    )
}

export default NetLogoProject
