import React from 'react'
import useDarkMode from '../hook/useDarkMode.js'
import {dgsFinal} from '../Images/imports'

const DgsProject = () => {
    useDarkMode()
    window.scrollTo(0, 0)
    return (
        <div className = 'font-PangramBold text-center text-2xl md:text-4xl bg-white dark:bg-black text-black dark:text-white'>
            <div className = 'h-screen flex flex-col justify-center items-center'>
        <h1 className = 'pb-6'>Check Out a Video of DGS in Action</h1>
        <video controls src={dgsFinal} type='video/mp4' className = 'w-11/12 md:w-7/12'></video>
    </div>
        </div>
    )
}

export default DgsProject
