import React from 'react'
import Project from './Project'
import {Link} from 'react-router-dom';
import useDarkMode from '../hook/useDarkMode.js'
import { framesThumbnail, cargonThumbnail, dgs, subcThumbnail, historyThumbnail, reshapeThumbnail, netLogoThumbnail, twitterThumbnail, vinopThumbnail, westernThumbnail } from '../Images/imports'

const MyProjects = () => {
    function smoothScroll(section) {
        document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
    }
    useDarkMode()
    window.scrollTo(0, 0)

    return (
        <div className='bg-white dark:bg-black text-black dark:text-white'id='Top'>
            <Link to={"/"}>
                <div className='absolute top-4 left-4 text-xs md:text-sm text-sky-700 dark:text-emerald-800 font-PangramBold'>
                    Back to Home
                </div>
            </Link>
            <div className = 'h-screen text-sky-700 dark:text-emerald-800 font-PangramBold text-7xl text-center \
            flex justify-center items-center overflow-hidden' style={{transition: 'height 999999s'}}>
                <div className=''>
                    <h1 className = 'lg:text-8xl xl:text-9xl 2xl:text-11xl px-4'>TECH PROJECTS</h1>
                    <h2 className = 'text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl pt-1'>Check out my coding projects</h2>
                </div>
            </div>

            <section className='text-center mx-auto w-10/12 lg:w-11/12 xl:w-10/12 mt-8 md:mt-12 lg:mt-16 xl:mt-20'>
                <Project title='Reshape Game' picture={reshapeThumbnail} buttonText='Play on Windows and Mac' description="A fun hyper-casual
                    game designed around changing your shape to travel through tunnels and over tracks! The A and D keys
                    will turn the cube character and the W and S keys will change the cube's size. Use the small rectangle to
                    cruise through tunnels, use the large rectangle to glide over tracks, and use the cube for turning sharp
                    corners. Don't forget: the cube character moves at speeds relative to its size!" 
                    extraLink='reshape' githubLink='https://github.com/cargon21/ReshapeGame' outsideLink={false} alt='reshape game icon'/>

                <Project title='DGS Game Finder' picture={dgs} buttonText='Learn More' description='When PC gamers want to buy a new game,
                    they often search through many websites in order to find the cheapest price. DGS removes this hassle by analyzing
                    the PC game digital marketplace to show you which discount distributor has the cheapest price for your desired game!'
                    extraLink='dgs-project' githubLink='https://github.com/cargon21/DGS' outsideLink={false} alt='dgs icon'/>

                <Project title='The Tale of Two Tweeters' picture={twitterThumbnail} buttonText='Learn More' description='You might be able to identify
                    whether a student or an entrepreneur wrote a given Tweet or maybe even when it was written, but what if you were only
                    given statistics about the Tweet rather than the Tweet itself? In this Data Science project, I calculated various
                    statistics about Tweets from students and entrepreneurs and applied them to various machine learning 
                    algorithms to try and predict who wrote any given Tweet, and when.' 
                    extraLink='twitter-project' githubLink='https://github.com/cargon21/tweet-predictor' outsideLink={false} alt='twitter project icon'/>

                <Project title='Suffolk Blockchain Club Site' picture={subcThumbnail} buttonText='Visit the Site' description="The Suffolk University Blockchain Club is building a 
                    community that adopts and advances blockchain application through education, teamwork, and innovation. This website was built to showcase what their
                    club offers."  
                    extraLink='https://sublockchain.org' githubLink='https://github.com/cargon21/subc-website' outsideLink={true} alt='image to blockchain club'/>     

                <Project title='RevoluSun Frames' picture={framesThumbnail} buttonText='Visit the Site' description="RevoluSun Photo Frames can help you show off 
                    your solar panel system or tell everyone that you’re a solar advocate! Just select a frame, upload an image, and download your unique frame."  
                    extraLink='https://massachusetts.revolusun.com/resources/photo-frames' outsideLink={true} alt='image to RevoluSun Frames Project'/>   

                <Project title='Cargon Game' picture={cargonThumbnail} buttonText='Play on Windows and Mac' description='My first ever video game! Travel across the map
                    to the beautiful meadow, but make sure to avoid the spiked stones throughout your journey. Use the A and D keys to move and the
                    spacebar to jump. A very difficult game, see if you can beat it!' 
                    extraLink='Cargon' githubLink='https://github.com/cargon21/tweet-predictor' outsideLink={false} alt='cargon game icon'/>    

                <Project title='Vinop Game Website' picture={vinopThumbnail} buttonText='Visit the Site' description=' Vinop is a totally free gaming website
                    that enables you to play a multitude of classic games right in your web browser. Play some old favorites such as Frogger, Snake, Space
                    Invaders, and more! Enjoy the nostalgia of these once cutting-edge video games.'
                    extraLink='https://vinopgames.com' githubLink='https://github.com/cargon21/Vinop-Games' outsideLink={true} alt='vinop games icon'/> 

                <Project title='NetLogo Research Project' picture={netLogoThumbnail} buttonText='Learn More' description=' This NetLogo model visualizes the
                    interactions of various stack exchange websites. Special thanks to Suffolk University Professor Dmitry Zinoviev for his support
                    throughout the creation of this project.'  
                    extraLink='netlogo-project' githubLink='https://github.com/cargon21/StackExchangeProject' outsideLink={false} alt='netlogo research project icon'/>     
                
                <Project title='Western Upholstery Website' picture={westernThumbnail} buttonText='Visit the Site' description='The official website of Western Upholstery
                    Supply in Denver. A simple display website with a modern interface that contains product, contact, and general information for the company.'  
                    extraLink='https://www.westernupholsterysupply.com' githubLink='https://github.com/cargon21/WesternSite' outsideLink={true} alt='western logo'/>

                <Project title='Science in Ancient China' picture={historyThumbnail} buttonText='Visit the Site' description='This Website was built for an
                    honors project in my World History course and was designed as a resource that students could use for the final exam. The site contains information
                    about scientific achievement in traditional Chinese agriculture, technology, and mathematics. Please note: This website was not designed for mobile devices.'  
                    extraLink='https://historyofchinesescience.com/' githubLink='https://github.com/cargon21/HistoryOfChineseScienceSite' outsideLink={true} alt='science in ancient china website'/>     

                <button onClick={() => smoothScroll('#Top')} className='mb-12 py-3 px-3'>Back to top</button>
            </section>
        </div>
    )
}

export default MyProjects
